import { Pipe, PipeTransform } from '@angular/core';
import { Withdrawal } from '@ducat-pro-wallets/swag-wallet';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'withdrawalStatusName',
})
export class WithdrawalStatusNamePipe implements PipeTransform {
    constructor(private transloco: TranslocoService) {}

    transform(status: Withdrawal.StatusEnum): string {
        const statuses = Withdrawal.StatusEnum;
        switch (status) {
            case statuses.Succeeded:
            case statuses.Failed:
            case statuses.Pending:
                return this.transloco.translate(`withdrawalStatus.${status}`);
            default:
                return status;
        }
    }
}
