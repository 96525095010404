import moment from 'moment';

/**
 * Генерация даты на основе переданных параметров.
 *
 * @param {string} [dateString] - Дата в строковом формате (например, '2023-12-30'). Если не передана, будет использована текущая дата.
 * @param {string} [modifier] - Модификатор для сдвига даты (например, 'subtract(1, "month")' для вычитания одного месяца).
 * @param {string} [format='YYYY-MM-DDTHH:mm:ssZ'] - Формат даты, в котором возвращаем результат (по умолчанию 'YYYY-MM-DDTHH:mm:ssZ').
 *
 * @returns {string} Строка с датой в нужном формате.
 */

export function generateDate(dateString = '', modifier = '', format = 'YYYY-MM-DDTHH:mm:ssZ') {
    let date = dateString ? moment(dateString) : moment();

    if (modifier) {
        const [action, ...args] = modifier.split(' '); // Разбираем строку, например, ("subtract 1 month") - будет означать дату на 1 месяц ранее.
        date = moment(date)[action](...args);
    }

    return date.format(format);
}
